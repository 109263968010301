/** @jsx jsx */
import { jsx } from 'theme-ui';
import PropTypes from 'prop-types';

const ContentContainer = ({ children }) => (
  <div
    sx={{
      maxWidth: 'maxContentWidth',
      mt: 0,
      mb: 2,
      mx: 'auto',
      px: 3,
      variant: 'variants.contentContainer',
    }}
  >
    {children}
  </div>
);

ContentContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ContentContainer;
