/* eslint-disable react/prop-types */
/** @jsx jsx */
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Img from 'gatsby-image';
import { Box, Heading, Container, Text, jsx, Styled } from 'theme-ui';
import getYouTubeId from 'get-youtube-id';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { format } from 'date-fns';
import { SEO } from '../utils';
import ContentContainer from '../components/content-container';
import SermonSeriesCard from '../components/SermonSeriesCard';
import { ReflexLink } from '../components/header/navbar/reflex-link';

const SermonPage = ({ sermon }) => {
  const hasVideo = !!sermon?.youtube?.url;
  const hasAudio = !!sermon.file;
  const partOfSeries = !!sermon?.series?.title;

  const youTubeId = hasVideo ? getYouTubeId(sermon.youtube.url) : undefined;

  const relatedSermons = sermon?.series?.sermons;

  function compare(a, b) {
    const sermonA = a.preachedDate;
    const sermonB = b.preachedDate;

    let comparison = 0;
    if (sermonA > sermonB) {
      comparison = 1;
    } else if (sermonA < sermonB) {
      comparison = -1;
    }
    return comparison * -1;
  }

  const sortedSermons = partOfSeries ? relatedSermons.sort(compare) : undefined;

  const sermonDate = format(new Date(sermon.preachedDate), 'MMMM d, yyyy');

  const data = useStaticQuery(graphql`
    query {
      sermonImage: file(relativePath: { eq: "sermon.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const sermonImage =
    sermon?.series?.image?.asset?.fluid ||
    data.sermonImage.childImageSharp.fluid;

  return (
    <>
      <SEO title={sermon.title} />
      <Box>
        {hasVideo ? (
          <Container sx={{ px: [0, null, null, 5] }}>
            <Box
              sx={{
                mt: 3,
                width: '100%',
                height: 0,
                paddingBottom: `${900 / 16}%`,
                position: 'relative',
                overflow: 'hidden',
                '& > iframe': {
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  top: 0,
                  bottom: 0,
                  left: 0,
                  border: 0,
                },
              }}
            >
              <iframe
                title={sermon.title}
                width="560"
                height="315"
                src={`https://www.youtube.com/embed/${youTubeId}`}
                frameBorder="0"
                allowFullScreen
              />
            </Box>
          </Container>
        ) : (
          <Container
            sx={{ maxWidth: ['100%', null, null, '800px'], mx: 'auto', mt: 3 }}
          >
            <Img fluid={sermonImage} />
          </Container>
        )}

        <ContentContainer sx={{ mb: 3 }}>
          <Heading
            as="h1"
            sx={{
              pt: 3,
              pb: 1,
              fontFamily: 'fancyFont',
              fontSize: 5,
            }}
          >
            {sermon.title}
          </Heading>
          {sermon.subtitle && (
            <Heading as="h3" sx={{ color: 'primary', fontFamily: 'fancyFont' }}>
              {sermon.subtitle}
            </Heading>
          )}
          <Text sx={{ fontSize: 0, pt: 2 }}>
            {`${sermonDate} • ${sermon.speaker.name}`}{' '}
            {sermon?.series?.title && ' • Series: '}
            {sermon?.series?.title && (
              <ReflexLink
                to={`/sermon-series/${sermon.series.slug.current}`}
                sx={{ textDecoration: 'none' }}
              >
                {sermon.series.title}
              </ReflexLink>
            )}
          </Text>

          {hasAudio && (
            <AudioPlayer
              sx={{ my: 3, maxWidth: '450px' }}
              src={`https://${process.env.GATSBY_S3}/${process.env.GATSBY_S3_BUCKET}/${sermon.file}`}
            />
          )}

          {partOfSeries && (
            <Box sx={{ my: 4 }}>
              <Styled.h4 sx={{ color: 'meddark' }}>
                Other Sermons from{' '}
                <ReflexLink
                  to={`/sermon-series/${sermon.series.slug.current}`}
                  sx={{ textDecoration: 'none' }}
                >
                  <span sx={{ color: 'dark' }}>{sermon.series.title}</span>
                </ReflexLink>
              </Styled.h4>
              {sortedSermons.map(sortedSermon => (
                <SermonSeriesCard key={sortedSermon.id} sermon={sortedSermon} />
              ))}
            </Box>
          )}
        </ContentContainer>
      </Box>
    </>
  );
};

export default SermonPage;
