import * as React from 'react';
import { graphql } from 'gatsby';
import SermonPage from '../../templates/SermonPage';

function Sermon(props) {
  const { sermon } = props.data;
  return <SermonPage sermon={sermon} />;
}

export default Sermon;

export const query = graphql`
  query($id: String!) {
    sermon: sanitySermon(id: { eq: $id }) {
      title
      id
      subtitle
      file
      youtube {
        url
      }
      slug {
        current
      }
      preachedDate
      series {
        title
        slug {
          current
        }
        image {
          asset {
            fluid(maxWidth: 900) {
              ...GatsbySanityImageFluid
            }
          }
        }
        sermons {
          title
          passage
          id
          series {
            title
            image {
              asset {
                fixed(width: 250, height: 156) {
                  ...GatsbySanityImageFixed
                }
              }
            }
          }
          slug {
            current
          }
          preachedDate
          speaker {
            name
            image {
              asset {
                fixed(width: 250, height: 156) {
                  ...GatsbySanityImageFixed
                }
              }
            }
          }
        }
      }
      speaker {
        name
      }
      passage
    }
  }
`;
