/* eslint-disable react/prop-types */
/** @jsx jsx */
import React from 'react';
import Img from 'gatsby-image';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { Box, jsx, Flex } from 'theme-ui';
import { format } from 'date-fns';

export const SermonLabel = ({ children }) => (
  <span sx={{ fontFamily: 'heading', fontSize: 0, color: 'primary' }}>
    {children}
  </span>
);

export default function SermonSeriesCard({ sermon }) {
  const sermonDate = format(new Date(sermon.preachedDate), 'MMMM d, yyyy');

  return (
    <Link
      to={`/sermons/${sermon.slug.current}`}
      sx={{ textDecoration: 'none', color: 'text' }}
    >
      <Flex
        as="article"
        sx={{
          p: 2,
          flexWrap: ['wrap', 'nowrap', 'nowrap'],
          justifyContent: 'left',
          boxShadow: ['xlcomplete'],
          borderRadius: ['xl'],
          mt: 2,
          ':hover': { bg: 'navbggrey' },
        }}
      >
        <Box
          sx={{ flex: '0 1 500px', pl: 3, lineHeight: '24px', mt: [2, 2, 1] }}
        >
          <h3 sx={{ my: 0 }}>{sermon.title}</h3>
          {sermon?.series?.title && (
            <h5 sx={{ mt: 0, mb: 1, color: 'primary' }}>
              {sermon.series.title}
            </h5>
          )}
          <p sx={{ fontSize: 1 }}>
            <SermonLabel>Preacher:</SermonLabel> {sermon.speaker.name}
            <br />
            <SermonLabel>Date:</SermonLabel> {sermonDate}
            <br />
            <SermonLabel>Passage:</SermonLabel> {sermon.passage}
          </p>
        </Box>
      </Flex>
    </Link>
  );
}
